var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticStyle: { overflow: "hidden" },
          attrs: { id: "body-content-area-all" },
        },
        [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              {
                staticClass: "has-text-centered heading",
                style: {
                  backgroundColor: "#ededed",
                  marginBottom: "0px",
                  width: "100%",
                  height: "52px",
                },
              },
              [
                _c(
                  "h1",
                  {
                    staticClass: "is-size-6",
                    staticStyle: { padding: "12px 0px" },
                  },
                  [_c("b", [_vm._v(_vm._s(_vm.pageName.toLocaleUpperCase()))])]
                ),
                _c(
                  "div",
                  {
                    staticClass: "add-button-container",
                    staticStyle: {
                      float: "right",
                      width: "215px",
                      position: "absolute",
                      right: ".5rem",
                      top: ".5rem",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        class: ["button is-accent"],
                        on: { click: _vm.openModal },
                      },
                      [_vm._m(0), _c("span", [_vm._v(" Add Document(s) ")])]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticStyle: { height: "87.5%" }, attrs: { id: "pagelayout" } },
              [
                _vm.documents && _vm.documents.length > 0
                  ? _c("Grid", {
                      staticStyle: { height: "100%" },
                      style: { height: "108%" },
                      attrs: {
                        filter: _vm.filter,
                        "data-items": _vm.documents,
                        sortable: false,
                        sort: _vm.sort,
                        filterable: false,
                        columns: _vm.staticColumns,
                        skip: _vm.skip,
                        take: _vm.take,
                        total: _vm.totalRecords,
                        pageable: false,
                      },
                      on: {
                        selectionchange: _vm.selectionchange,
                        headerselectionchange: _vm.headerselectionchange,
                        filterchange: _vm.filterchange,
                        sortchange: _vm.sortchange,
                        pagechange: _vm.pagechange,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "NameCell",
                            fn: function (ref) {
                              var props = ref.props
                              return [
                                _c(
                                  "td",
                                  {
                                    staticClass: "editable",
                                    staticStyle: { padding: "0" },
                                  },
                                  [
                                    _c(
                                      "v-popover",
                                      {
                                        staticStyle: {
                                          height: "100%",
                                          width: "75%",
                                          "text-overflow": "clip!important",
                                        },
                                        attrs: {
                                          open: props.dataItem.showSaveDialog,
                                          placement: "right-end",
                                          trigger: "manual",
                                          autoHide: false,
                                        },
                                      },
                                      [
                                        _c("div", {}, [
                                          _vm.isOwnedByUser(props.dataItem)
                                            ? _c("input", {
                                                staticStyle: {
                                                  height: "100%",
                                                  width: "100%",
                                                  "text-overflow":
                                                    "clip!important",
                                                },
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value:
                                                    props.dataItem.displayName,
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    props.dataItem.showSaveDialog =
                                                      $event.target.value !==
                                                      props.dataItem.displayName
                                                    props.dataItem.event =
                                                      $event
                                                    props.dataItem.displayName =
                                                      $event.target.value
                                                  },
                                                  keyup: [
                                                    function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "esc",
                                                          27,
                                                          $event.key,
                                                          ["Esc", "Escape"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      _vm.reset(
                                                        $event,
                                                        props.dataItem
                                                      )
                                                      props.dataItem.event =
                                                        null
                                                      props.dataItem.showSaveDialog = false
                                                    },
                                                    function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      props.dataItem.showSaveDialog =
                                                        $event.target.value !==
                                                        props.dataItem
                                                          .displayName
                                                      props.dataItem.event =
                                                        $event
                                                      props.dataItem.displayName =
                                                        $event.target.value
                                                    },
                                                  ],
                                                },
                                              })
                                            : _c("input", {
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value:
                                                    props.dataItem.displayName,
                                                },
                                                on: {
                                                  mousedown: function ($event) {
                                                    $event.preventDefault()
                                                  },
                                                },
                                              }),
                                        ]),
                                        _c(
                                          "template",
                                          {
                                            attrs: { id: "popover" },
                                            slot: "popover",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "popover-contain",
                                              },
                                              [
                                                _vm._v(" Are you sure? "),
                                                _c(
                                                  "div",
                                                  { staticClass: "actions" },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        attrs: { cancel: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            _vm.reset(
                                                              $event,
                                                              props.dataItem
                                                            )
                                                            props.dataItem.event =
                                                              null
                                                            props.dataItem.showSaveDialog = false
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Cancel")]
                                                    ),
                                                    _c(
                                                      "button",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            _vm.submitChanges(
                                                              props.dataItem,
                                                              "displayName"
                                                            )
                                                            props.dataItem.event =
                                                              null
                                                            props.dataItem.showSaveDialog = false
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Save")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                          {
                            key: "TypeCell",
                            fn: function (ref) {
                              var props = ref.props
                              return [
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        props.dataItem.extension
                                          .replace(".", "")
                                          .toUpperCase()
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "SizeCell",
                            fn: function (ref) {
                              var props = ref.props
                              return [
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "right" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getFileSize(
                                            props.dataItem.fileSize
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "UploadDateCell",
                            fn: function (ref) {
                              var props = ref.props
                              return [
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .getUploadDate(
                                            props.dataItem.uploadDate
                                          )
                                          .format("MM/DD/YYYY")
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "UploadedByCell",
                            fn: function (ref) {
                              var props = ref.props
                              return [
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(props.dataItem.uploadUserName) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "SharingCell",
                            fn: function (ref) {
                              var props = ref.props
                              return [
                                _vm.isOwnedByUser(props.dataItem)
                                  ? _c("td", { staticClass: "editable" }, [
                                      _c(
                                        "select",
                                        {
                                          on: {
                                            change: function ($event) {
                                              return _vm.submitChanges(
                                                props.dataItem,
                                                "isShared",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            {
                                              domProps: {
                                                value: true,
                                                selected:
                                                  props.dataItem.isShared,
                                              },
                                            },
                                            [_vm._v("Shared")]
                                          ),
                                          _c(
                                            "option",
                                            {
                                              domProps: {
                                                value: false,
                                                selected:
                                                  !props.dataItem.isShared,
                                              },
                                            },
                                            [_vm._v("Private")]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _c("td", { staticClass: "editable" }, [
                                      _c(
                                        "select",
                                        {
                                          ref: "isSharedDropdown",
                                          on: {
                                            mousedown: function ($event) {
                                              $event.preventDefault()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { domProps: { selected: true } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  props.dataItem.isShared
                                                    ? "Shared"
                                                    : "Private"
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "ActionCell",
                            fn: function (ref) {
                              var props = ref.props
                              return [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      padding: "0",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "action-cell" }, [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip.left-start",
                                              value: {
                                                content: "Download Document",
                                                container: false,
                                                classes: ["tooltip"],
                                              },
                                              expression:
                                                "{ content: 'Download Document', container: false, classes: ['tooltip'] }",
                                              modifiers: { "left-start": true },
                                            },
                                          ],
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: _vm.getDocumentPath(
                                                  props.dataItem
                                                ),
                                                download: "",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-download",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip.left-start",
                                              value: {
                                                content: "View Document",
                                                container: false,
                                                classes: ["tooltip"],
                                              },
                                              expression:
                                                "{ content: 'View Document', container: false, classes: ['tooltip'] }",
                                              modifiers: { "left-start": true },
                                            },
                                          ],
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: { href: "" },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.viewDocument(
                                                    props.dataItem
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-eye",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip.left-start",
                                              value: {
                                                content: "Delete Document",
                                                container: false,
                                                classes: ["tooltip"],
                                              },
                                              expression:
                                                "{ content: 'Delete Document', container: false, classes: ['tooltip'] }",
                                              modifiers: { "left-start": true },
                                            },
                                          ],
                                        },
                                        [
                                          _vm.isOwnedByUser(props.dataItem)
                                            ? _c(
                                                "a",
                                                {
                                                  attrs: { href: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.deleteDocument(
                                                        props.dataItem
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-trash",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2999264192
                      ),
                    })
                  : _c("div", [_c("center", [_vm._v("No documents")])], 1),
              ],
              1
            ),
          ]),
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitFiles.apply(null, arguments)
                },
              },
            },
            [
              _c("modal", {
                ref: "upload",
                on: {
                  close: function ($event) {
                    return _vm.removeFiles()
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return undefined
                      },
                      proxy: true,
                    },
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _vm.filePondIsLoading ? _c("div") : _vm._e(),
                          _c(
                            "div",
                            {
                              ref: "filePondWrapper",
                              class: [
                                "filePondWrapper",
                                _vm.$refs.filePond &&
                                _vm.$refs.filePond.getFiles().length < 1
                                  ? "has-no-files"
                                  : "",
                              ],
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.openFileBrowser.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c("file-pond", {
                                ref: "filePond",
                                attrs: {
                                  name: "file",
                                  "label-idle": _vm.filePondLabel,
                                  "allow-multiple": "true",
                                  "accepted-file-types":
                                    "application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                  files: _vm.input.files,
                                  dropOnElement: false,
                                  dropOnPage: true,
                                  credits: [],
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                  addfilestart: _vm.addfile,
                                  removefile: _vm.removefile,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    _vm.input.files.length !== 0
                      ? {
                          key: "footer",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "modal-footer" }, [
                                _c(
                                  "button",
                                  {
                                    ref: "saveButton",
                                    class: [_vm.isSaving ? "saving" : ""],
                                    attrs: {
                                      type: "submit",
                                      disabled: _vm.isSaving,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.isSaving
                                            ? "Saving"
                                            : "Save Document"
                                        ) +
                                        " "
                                    ),
                                    _vm.isSaving ? _c("spin-loader") : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
          _vm.showDeleteModal
            ? _c(
                "div",
                {
                  staticClass: "modal is-active",
                  attrs: { id: "delete-modal" },
                },
                [
                  _c("div", {
                    staticClass: "modal-background",
                    on: {
                      click: function ($event) {
                        _vm.showDeleteModal = false
                      },
                    },
                  }),
                  _c("div", { staticClass: "modal-card" }, [
                    _c(
                      "header",
                      { staticClass: "modal-card-head has-bg-danger" },
                      [
                        _c(
                          "div",
                          { staticClass: "modal-card-title has-bg-danger" },
                          [_vm._v("Confirm Delete")]
                        ),
                        _c("a", {
                          staticClass: "delete",
                          attrs: { "aria-label": "close" },
                          on: {
                            click: function ($event) {
                              _vm.showDeleteModal = false
                            },
                          },
                        }),
                      ]
                    ),
                    _c("section", { staticClass: "modal-card-body" }, [
                      _c("div", { staticClass: "content" }, [
                        _vm._v(' Type "'),
                        _c("b", [_vm._v("delete")]),
                        _vm._v(
                          '" to confirm that you want to delete "' +
                            _vm._s(_vm.selectedFile.name) +
                            '" '
                        ),
                      ]),
                      _c("div", { staticClass: "field" }, [
                        _c("div", { staticClass: "control" }, [
                          _c("input", {
                            directives: [
                              { name: "focus", rawName: "v-focus" },
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.confirmationText,
                                expression: "confirmationText",
                                modifiers: { trim: true },
                              },
                            ],
                            staticClass: "input",
                            attrs: {
                              type: "text",
                              placeholder:
                                "type `delete` and then click confirm",
                            },
                            domProps: { value: _vm.confirmationText },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                $event.preventDefault()
                                return _vm.deleteIfConfirmed.apply(
                                  null,
                                  arguments
                                )
                              },
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.confirmationText =
                                  $event.target.value.trim()
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _c("footer", { staticClass: "modal-card-foot" }, [
                      _c(
                        "a",
                        {
                          staticClass: "button is-danger",
                          attrs: {
                            disabled: _vm.confirmationText !== "delete",
                          },
                          on: { click: _vm.deleteIfConfirmed },
                        },
                        [_vm._m(1), _c("span", [_vm._v("Confirm")])]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "button",
                          on: {
                            click: function ($event) {
                              _vm.showDeleteModal = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-plus-square" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }